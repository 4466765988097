/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { graphql } from 'gatsby'
import LineupPage from '~/components/Lineup/Page'

const LineupTemplate = ({ data: { contentfulLineup: lineup } }) => {
  return (
    <Layout breadcrumb={{ crumbLabel: lineup.name }}>
      <Metadata
        title={lineup.name}
        description={`${lineup.name} lineup page`}
      />
      <LineupPage lineup={lineup} />
    </Layout>
  )
}

export const query = graphql`
  query ShopPageQuery($slug: String!) {
    contentfulLineup(slug: { eq: $slug }) {
      ...LineupPageFragment
    }
  }
`

export default LineupTemplate
