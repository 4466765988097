/** @jsx jsx */
import { Link } from 'gatsby'
import { Container, Flex, Box, Heading, jsx } from 'theme-ui'
import ProductList from '~/components/Product/List'
import { IoAddSharp } from 'react-icons/io5'
import { Button } from '@theme-ui/components'

const LineupPage = ({ lineup }) => {
  const collections = lineup.collections?.reduce((acc, collection) => {
    if (collection.__typename === 'ContentfulLineup') {
      return [...acc, ...collection.collections]
    }

    return [...acc, collection]
  }, [])

  const isBikePage =
    collections &&
    collections[0].products &&
    collections[0].products[0].productType?.slug === 'electric-motorbike'

  return (
    <Container sx={{ paddingTop: 0, paddingBottom: ['70px', null, 0] }}>
      <Heading
        as="h1"
        variant="h1"
        sx={{
          fontWeight: 'medium',
          textTransform: 'uppercase',
          textAlign: 'center',
          marginBottom: [3, null, null, 4, 5],
          marginTop: ['-1.3rem', null, null, 0, null],
          fontSize: [6, null, null, 8]
        }}
      >
        {lineup.name}
      </Heading>

      {collections?.map(
        collection =>
          collection.__typename === 'ContentfulCollection' && (
            <Flex
              as="section"
              sx={{
                flexFlow: 'column nowrap',
                alignItems: 'stretch',
                paddingBottom: ['2rem', null, 6],
                ':not(:last-of-type)': {
                  marginBottom: ['2rem', null, 6],
                  borderBottom: `1px solid #C6C6C6`
                }
              }}
              key={collection.id}
            >
              <Heading
                as="h2"
                variant="h3"
                sx={{
                  fontSize: ['1.5rem', null, null, '3rem'],
                  marginBottom: 3,
                  fontWeight: 'medium',
                  textTransform: 'uppercase'
                }}
              >
                {collection.title}
              </Heading>

              <ProductList
                collectionName={collection.slug}
                lineup={lineup.name}
                products={collection.products ? collection.products : []}
              />

              {!isBikePage && collection.products?.length > 4 && (
                <Link
                  to={collection.slug}
                  aria-label={`View More products in ${collection.name}`}
                  sx={{
                    marginTop: [3, null, 4],
                    alignSelf: 'flex-end',
                    fontWeight: 'medium',
                    fontSize: ['1.1rem', 2, null, 5],
                    fontFamily: 'monospace',
                    textDecoration: 'underline'
                  }}
                >
                  View More
                  <IoAddSharp sx={{ verticalAlign: 'text-top' }} />
                </Link>
              )}
            </Flex>
          )
      )}
      {lineup.showCompareBikesButton && (
        <Box
          sx={{
            textAlign: 'center',
            mb: [0, null, '50px'],
            mt: ['30px', null, 0]
          }}
        >
          <Button as={Link} to="/compare-bikes">
            COMPARE BIKES
          </Button>
        </Box>
      )}
    </Container>
  )
}

export default LineupPage
